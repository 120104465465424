<template>
    <section class="mt-16">
      
      <RolesComponent />
      </section>
</template>
<script>
    
import RolesComponent from '@/components/roles/RolesComponent.vue';

export default{
    components: {
        RolesComponent
    }
}
</script>