<template>
    <section class="mt-16">
      
      <PermissionsComponent />
      </section>
</template>
<script>
    
import PermissionsComponent from '@/components/permissions/PermissionsComponent.vue';

export default{
    components: {
        PermissionsComponent
    }
}
</script>