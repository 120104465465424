var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-10"},[_c('v-card-text',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Código *","clearable":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"display_name","name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Nome *","clearable":"","error-messages":errors},model:{value:(_vm.fields.display_name),callback:function ($$v) {_vm.$set(_vm.fields, "display_name", $$v)},expression:"fields.display_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"permissions","name":"Permissões","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiple-select',{attrs:{"items":_vm.permissions,"item-text":"display_name","item-value":"id","menu-props":"auto","label":"Permissões *","prepend-inner-icon":"mdi-lock","error-messages":errors,"return-object":""},model:{value:(_vm.fields.permissions),callback:function ($$v) {_vm.$set(_vm.fields, "permissions", $$v)},expression:"fields.permissions"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"users","name":"Utilizadores"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiple-select',{attrs:{"items":_vm.users,"item-text":function (item) { return 'Nome: ' + item.name + ' - Username: '+ item.username; },"item-value":"id","menu-props":"auto","label":"Utilizadores","prepend-inner-icon":"mdi-account-multiple","error-messages":errors,"return-object":""},model:{value:(_vm.fields.users),callback:function ($$v) {_vm.$set(_vm.fields, "users", $$v)},expression:"fields.users"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Gravar ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Role gravado com sucesso! ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }