import BaseApi from "./BaseApi";

export default class Languages extends BaseApi{
    construct() {}

    static async list() {
        
        return await Languages.getAxiosInstance().get(Languages.getApiUrl() + 'languages-available');
    }


    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/languages/";
    }

}