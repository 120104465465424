<template>
    <section class="mt-16">
      <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-hard-hat</v-icon> <span>Criar código promocional</span>
        </h1>
  
        <div class="mt-16" :style="this.$root.$vuetify.theme.isDark ? '' : 'background-color: rgba(255, 255, 255, 0.75) !important;'">
          <Promocode method="create"></Promocode>
        </div>
    </section>
  </template>
  <script>
  import Promocode from '@/components/promocodes/Promocode'
  
  export default {
    components: {
        Promocode
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "promocodes.write"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [{
                text: 'Gestão de Códigos Promocionais',
                disabled: false,
                to: '/promocodes',
                exact: true,
            },
            {
                text: 'Criar código promocional',
                disabled: true,
                to: window.location.href,
                exact: true,
            },
        ];
      }
    }
  };
  </script>
  