import BaseApi from "./BaseApi";

export default class Collaborators extends BaseApi{
    construct() {}

    static async search(params) {
        return await Collaborators.getAxiosInstance().get(Collaborators.getApiUrl(),{params: params});
    }

    static async list(params) {
        let url = Collaborators.getApiUrl() + "list";

        return Collaborators.getAxiosInstance().get(url,{params: params});
    }

    static async create(params) {
        return await Collaborators.getAxiosInstance().post(Collaborators.getApiUrl(),params);
    }

    static async update(params) {
        return await Collaborators.getAxiosInstance().put(Collaborators.getApiUrl() + params.id,params);
    }

    static async delete(id) {
        return await Collaborators.getAxiosInstance().delete(Collaborators.getApiUrl() + id);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/collaborators/";
    }

}