<template>
    <span style="cursor: pointer;">
      <Tooltip
        class="my-4 mr-4"
        color="warning"
        text="Ativar"
        top
      >
        <v-icon
          small
          color="warning"
          v-bind="$attrs"
          v-on="$listeners"
        >
          mdi-led-on
        </v-icon>
      </Tooltip>
    </span>
  </template>
  
  <script>
    import Tooltip from '@/components/ui/Tooltip.vue';
    export default {
      name: 'uiIconActivate',
      components: {
        Tooltip
      },
    }
  </script>
  
  <style lang="scss" scoped>
  </style>