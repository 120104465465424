import BaseApi from "./BaseApi";

export default class Permission extends BaseApi{
    construct() {}

    static async search(params) {
        return await Permission.getAxiosInstance().get(Permission.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Permission.getAxiosInstance().post(Permission.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Permission.getAxiosInstance().put(Permission.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await Permission.getAxiosInstance().delete(Permission.getApiUrl() + id);
    }

    static async find(id) {
        return await Permission.getAxiosInstance().get(Permission.getApiUrl() + id);
    }

    static async list(params) {
        let url = Permission.getApiUrl() + "list";

        return Permission.getAxiosInstance().get(url,{params: params});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/permissions/";
    }
}