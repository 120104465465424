var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-10"},[_c('v-card-text',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"mb-16",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_vm._e(),_c('fieldset',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Nome *","clearable":"","error-messages":errors},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Nome","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Ordem aparição","type":"number","clearable":"","error-messages":errors},model:{value:(_vm.fields.order),callback:function ($$v) {_vm.$set(_vm.fields, "order", $$v)},expression:"fields.order"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"vid":"Preço","name":"Preço","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Preço *","clearable":"","type":"number","error-messages":errors},model:{value:(_vm.fields.price),callback:function ($$v) {_vm.$set(_vm.fields, "price", $$v)},expression:"fields.price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"vid":"Mes","name":"Mes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-calendar","label":"Meses *","clearable":"","hint":"O preço total de checkout será calculado da seguinte forma: preço x meses","persistent-hint":"","type":"number","error-messages":errors},model:{value:(_vm.fields.in_month),callback:function ($$v) {_vm.$set(_vm.fields, "in_month", $$v)},expression:"fields.in_month"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"vid":"Tipo Instituição","name":"Tipo Instituição","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":['private', 'public'],"prepend-inner-icon":"mdi-calendar","label":"Tipo instituição","clearable":"","persistent-hint":"","hint":"Deixar a null o que for Trial","type":"number","error-messages":errors},model:{value:(_vm.fields.institutional_type),callback:function ($$v) {_vm.$set(_vm.fields, "institutional_type", $$v)},expression:"fields.institutional_type"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"vid":"Dias","name":"Dias","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Dias *","clearable":"","type":"number","error-messages":errors},model:{value:(_vm.fields.days),callback:function ($$v) {_vm.$set(_vm.fields, "days", $$v)},expression:"fields.days"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"vid":"Colaboradores","name":"Colaboradores","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Qtd. Colaboradores *","clearable":"","error-messages":errors},model:{value:(_vm.fields.n_workers),callback:function ($$v) {_vm.$set(_vm.fields, "n_workers", $$v)},expression:"fields.n_workers"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"Visibilidade","name":"Visibilidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"error-messages":errors,"label":"Visivel ao cliente?"},model:{value:(_vm.fields.client_visible),callback:function ($$v) {_vm.$set(_vm.fields, "client_visible", $$v)},expression:"fields.client_visible"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"vid":"site","name":"site","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"error-messages":errors,"label":"Aplicação direta no site?"},model:{value:(_vm.fields.website_direct_promo),callback:function ($$v) {_vm.$set(_vm.fields, "website_direct_promo", $$v)},expression:"fields.website_direct_promo"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"description","name":"Descrição","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"prepend-inner-icon":"mdi-account-details","label":"Descrição *","clearable":"","error-messages":errors},model:{value:(_vm.fields.description),callback:function ($$v) {_vm.$set(_vm.fields, "description", $$v)},expression:"fields.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"vid":"promocode","name":"Promocode","rules":"|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.promocodes,"prepend-inner-icon":"mdi-account-details","label":"Promocode","item-text":"name","item-value":"id","clearable":"","error-messages":errors},model:{value:(_vm.fields.promocode_id),callback:function ($$v) {_vm.$set(_vm.fields, "promocode_id", $$v)},expression:"fields.promocode_id"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-6",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"disabled":invalid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Gravar ")])],1)],1)],1),_c('DialogSuccess',{attrs:{"opened":_vm.success},on:{"on-ok":_vm.onSuccess}},[_vm._v(" Tipo de subscrição gravado com sucesso! ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }