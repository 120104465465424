<template>
  <section class="mt-16">
    <v-breadcrumbs
      large
      :items="breadcrumbs"
      divider="/"
      class="pl-0"
    ></v-breadcrumbs>

    <h1 class="primary--text">
      <v-icon color="primary">mdi-bell</v-icon> <span>Notificações</span>
    </h1>

    <v-btn
      class="float-right"
      :disabled="!canRead"
      color="primary"
      right
      depressed
      @click="readAll"
    >
      Marcar Notificações como lidas
    </v-btn>

    <v-timeline
      class="mt-15"
      align-top
      :dense="$vuetify.breakpoint.smAndDown"
    >
      <v-timeline-item
        v-for="(item, i) in items"
        :key="i"
        :color="item.read ? 'grey' : item.color"
        :icon="item.icon"
        fill-dot
        large
      >
        <v-card elevation="4" color="gray">
          <v-card-title class="title">
            {{item.title}} 
          </v-card-title>
          <v-card-subtitle>
            {{ item.creation }}
          </v-card-subtitle>
          <v-card-text class="white text--primary">
            <p class="pt-2 text-content">{{item.created_at}}</p>
            <p class="pt-2 text-content" v-if="item.type != 'toApprove' && item.type != 'toDo'">
              {{ item.description }}
            </p>
            <p v-else v-for="(i, index) in computeData(item)" :key="index">
              <a :href="i.url">{{ i.day }}</a>
            </p>
            <v-btn
              v-if="item.type == 'REJECTION'"
              :color="item.read ? 'grey' : 'primary'"
              class="mx-0"
              :class="item.read ? 'btn-readed' : ''"
              depressed
              @click="verDiario(item)"
            >
              Ver Diário
            </v-btn>
            <v-btn
              v-else-if="item.downloadable"
              :color="item.read ? 'grey' : 'primary'"
              class="mx-0"
              :class="item.read ? 'btn-readed' : ''"
              depressed
              :href="item.url"
            >
              Download
            </v-btn>
            <p
              v-else-if="item.url != 'null' && !item.downloadable" class="caption">
              <v-btn @click="$router.push(getObjectURL(item))" small color="secondary"><v-icon class="mr-2">mdi-eye</v-icon>Ver</v-btn>
            </p>
            <v-btn
              v-if="false"
              :color="item.read ? 'grey' : 'primary'"
              class="mx-0"
              :class="item.read ? 'btn-readed' : ''"
              depressed
              :href="item.url"
            >
              Criar Diário
            </v-btn>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <v-row justify="center">
        <v-btn
          class="mt-12 mb-16"
          :disabled="!hasMore"
          color="primary"
          right
          depressed
          @click="more"
        >
          Ver Mais
        </v-btn>
    </v-row>
    <DialogSuccess :opened="read" @on-ok="read = false" >
      Notificações marcadas como lidas!
    </DialogSuccess>
</section>
</template>

<script>
import DialogSuccess from '@/components/ui/DialogSuccess.vue';
import Notification from "@/api/Notification.js";
export default {
  name: 'TheNotifications',
  created() {
    this.listNotifications();
  },
  components: {
    DialogSuccess,
  },
  data(){
    return {
      breadcrumbs: [{
        text: 'Dashboard',
        disabled: false,
        href: '/admin',
      },{
        text: 'Notificações',
        disabled: true,
        href: window.location.href,
      }],
      items: [],
      canRead: false,
      hasMore:false,
      read: false,
      offset: 0,
    }
  },
  methods: {
    getObjectURL(item){
      if(item.json == undefined)
        return ''

      if(item.type.includes('InvoiceExpired')){
        return '/tenants/update/' + item.json.client + '/Faturas/' + item.json.invoice_id
      }
    },
    computeData(item){
        let data = JSON.parse(item.description)
        let returner = [];

        if(item.type == 'toDo'){
          data.forEach(element => {
            

            returner.push({
              'day': element.dia,
              'url':  element.url
            })
          });

        }

        if(item.type == 'toApprove'){
          data.forEach(element => {
            
            returner.push({
              'day': element.date,
              'url':  element.url
            })
          });

        }

        return returner
    },
    notificationDescription(item){
      if(item.type != 'toApprove' && item.type != 'toDo')
        return item.description

        
      if(item.type == 'toDo'){

        let data = JSON.parse(item.description)
        let txt = ''
        data.forEach(element => {
          
          txt = txt + '<a>- ' + element.dia + "</a>\n\n";
        });
        return txt

      }

      if(item.type == 'toApprove'){
        let data = JSON.parse(item.description)
        let txt = ''
        data.forEach(element => {
          
          txt = txt + '<a>- ' + element.date + "</a>\n\n";
        });
        return txt

      }
    },  
    verDiario(item){
      this.$router.push({ path: 'diaries/search/' + item.url })
    },
    getIcon(notification){
      
      if(notification.type.includes('InvoiceExpired'))
        return {
          icon: 'mdi-receipt-text-remove',
          color: 'error'
        }
      if(notification.type.includes('InvoiceExpiring'))
        return {
          icon: 'mdi-receipt-text-clock',
          color: 'warning'
        }


      switch(notification.type){
        case 'xls':
          return {
            icon:'mdi-file-excel',
            color: 'success'
          };
        case 'xml':
          return {
            icon:'mdi-xml',
            color: 'success'
          };
        case 'warning':
          return {
            icon:'mdi-alert',
            color: 'warning'
          };
        default:
          return {
            icon :'comment-text-outline',
            color:'success'
          };
      }
    },
    getButtonName(notification){

      return notification.downloadable ? "Download" : "Ver Mais";
    },
    readAll(){
      this.read = false;

      Notification.read().then(() => {
          this.read = true;

          this.items.forEach(notification => {

            notification.read = true;
            

          });
          this.canRead = false;
          this.$emit('read-notifications')
      });
    },
    listNotifications(){
       Notification.list(0, this.offset).then(({data}) => {
         data.forEach(notification  => {


           let dataObject = new Date(notification.created_at);

           notification.data.creation = dataObject.toLocaleDateString('pt-PT') + ' ' + dataObject.getHours() + ':' + dataObject.getMinutes();

           notification.data.read = notification.read_at != null; 

           notification.data.color = notification.data.read ? "grey" : this.getIcon(notification.data).color; 

           notification.data.icon = this.getIcon(notification.data).icon;

           this.items.push(notification.data);

           this.canRead = notification.data.read ? this.canRead : true;
          });

        this.hasMore = data.length >= 20;
      });
    },
    more(){
      this.offset+=20;

      this.listNotifications();
    }
  }
}
</script>

<style scoped>
.v-card__title {
  color: #673ab7;
}
.v-card__text {
  background: #15162608 !important;
}
.text-content {
  color:gray
}
.btn-readed {
  color: white;
}
</style>