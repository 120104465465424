import BaseApi from "./BaseApi";

export default class SubscriptionTypes extends BaseApi{
    construct() {}

    static async search(params) {
        return await SubscriptionTypes.getAxiosInstance().get(SubscriptionTypes.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await SubscriptionTypes.getAxiosInstance().post(SubscriptionTypes.getApiUrl(),params);
    }

    static async update(params, id) {
        return await SubscriptionTypes.getAxiosInstance().put(SubscriptionTypes.getApiUrl() + id,params);
    }

    static async delete(id) {
        return await SubscriptionTypes.getAxiosInstance().delete(SubscriptionTypes.getApiUrl() + id);
    }

    static async find(id) {
        return await SubscriptionTypes.getAxiosInstance().get(SubscriptionTypes.getApiUrl() + id);
    }

    static async list() {
        let url = SubscriptionTypes.getApiUrl() + "list";

        return SubscriptionTypes.getAxiosInstance().get(url);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/subscriptions-types/";
    }

}