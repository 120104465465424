<template>
    <section class="mt-16">
        <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          class="pl-0"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-domain</v-icon> <span>Registo de tenants</span>
        </h1>
  
        <div class="mt-16">
          <Tenant method="update"></Tenant>
        </div>
    </section>
  </template>
  <script>
  import Tenant from '@/components/tenants/main/Tenant'
  
  export default {
    components: {
      Tenant
    },
    beforeCreate(){
      document.title = "Ignoto - Registo de tenants";
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$root.session.hasPermission(["super", "tenants.read"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Dashboard',
            disabled: false,
            to: '/admin',
            exact: true,
          },
          {
            text: 'Gestão tenants',
            disabled: false,
            to: '/tenants',
            exact: true,
          },
          {
            text: 'Registo de tentants',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  