import BaseApi from "./BaseApi";
export default class TenantRole extends BaseApi{
    construct() {}

  

    static async list(params, tenant) {
        let url = TenantRole.getFullApiUrl(tenant) + "list";

        return TenantRole.getAxiosInstance().get(url,{params: params});
    }

    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/roles/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/roles/";
    }
}