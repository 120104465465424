<template>
    <section class="mt-16">
      <v-breadcrumbs
          large
          :items="crumbs"
          divider="/"
          light
          :class="this.$root.$vuetify.theme.isDark ? 'primary--text pl-0' : 'pl-0'"
        ></v-breadcrumbs>
  
        <h1 class="primary--text">
          <v-icon color="primary">mdi-hard-hat</v-icon> <span>Atualizar código promocional</span>
        </h1>
  
        <div class="mt-16">
          <v-progress-linear v-if="loading"
            indeterminate
            class="global-loader"
          ></v-progress-linear>
  
          <template v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-skeleton-loader
                  type="text"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-6" align="center" justify="space-around">
              <v-skeleton-loader
                  type="button"
                ></v-skeleton-loader>
            </v-row>
          </template>
          <Promocode v-else method="update" :promocode="promocode" />
        </div>
    </section>
  </template>
  <script>
  import Promocode from '@/components/promocodes/Promocode'
  import Promocodes from "@/api/Promocodes.js";
  
  export default {
    components: {
        Promocode
    },
    data: () => ({
        promocode: {},
        loading:true
    }),
    mounted(){
      if(!this.$root.session.hasPermission(["super", "promocodes.update"])) {
        this.$router.push('/admin');
      }
  
      Promocodes.find(this.$route.params.id).then(({data}) => { this.promocode = data; this.loading = false;});
    },
    methods:{
      refresh(){
        Promocodes.find(this.$route.params.id).then(({data}) => { this.promocode = data; this.loading = false;});
      }
    },
    computed: {
      crumbs: function() {
        return [{
                text: 'Gestão de Códigos Promocionais',
                disabled: false,
                to: '/promocodes',
                exact: true,
            },
            {
                text: 'Atualizar código promocional',
                disabled: true,
                to: window.location.href,
                exact: true,
            },
        ];
      }
    }
  };
  </script>
  