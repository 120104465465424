<template>
    <div class="mt-16">
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-domain</v-icon> <span>Nifs utilizados</span>
      </h1>
        <div style="position:relative">

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16 elevation-10"
          locale="pt-pt"
        >

      

        </v-data-table>
      </div>
      
    </div>
</template>
<script>

import UsedNif from "@/api/UsedNif.js";

export default {
  components: {
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchUsedNifs()}, 10);
      },
      deep: true,
    },
  },
  data: () => ({
    headers: [
      { 
        text: 'Empresa', 
        value: 'client_name', 
        sortable: false 
      },
      {
        text: 'NIF',
        sortable: false,
        value: 'nif',
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
        
    },
    users:[]
  }),
  mounted(){

    /*if(!this.$root.session.hasPermission(["super", "promocodes.read"])) {
      this.$router.push('/admin');
    }*/

    this.fillBaseData();
  },
  methods:{
    resetUsedNifs()
    {
      this.filter = {};

      this.searchUsedNifs();
    },
    fillBaseData() {

      if(localStorage["used-nifs-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["used-nifs-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchUsedNifs(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
      };
    
      Object.assign(request, filter);

      localStorage["used-nifs-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      UsedNif.search(request).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Nifs utilizados',
          disabled: true,
          to: window.location.href,
          exact: true,
        },
      ];
    }
  }
};
</script>
