import BaseApi from "./BaseApi";

export default class UsedNif extends BaseApi{
    construct() {}

    static async search(params) {
        return await UsedNif.getAxiosInstance().get(UsedNif.getApiUrl(),{params: params});
    }


    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/used-nifs/";
    }

}