<template>
  <v-select
    v-model="selectedValues"
    v-bind="$attrs"
    menu-props="auto"
    multiple
    clearable
    @click:clear="$emit('selected', [])"
    @input="input"
  >
    <template v-slot:selection="{ index }">
      <span
        v-if="index === 0"
        class="grey--text caption"
      >
        (+{{ selectedValues.length }} selecionados)
      </span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="toggle()"
      >
        <v-list-item-action>
          <v-icon :color="selectedValues.length > 0 ? 'primary darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Todos
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'uiMultipleSelect',
  model: {
    prop: 'values',
    event: 'selected'
  },
  props: {
    values: Array,
  },
  watch: { 
    values: {
      immediate: true, 
      handler (val) {

        val = val == undefined ? [] : val;

        this.selectedValues = val;

        this.$emit("selected", this.selectedValues);
      }
    },
    selectedValues: {
      immediate: true, 
      handler (val) {
        
        val = val == undefined ? [] : val;
        
        this.$emit("selected", val);
      }
    },
  },
  data(){
    return {
      selectedValues: [],
    }
  },
  computed: {
      all () {
        return this.selectedValues.length === this.$attrs.items.length
      },
      some () {
        return this.selectedValues.length > 0 && !this.all
      },
      icon () {
        if (this.all) return 'mdi-close-box'
        if (this.some) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
    },
  methods: {
    toggle () {
      this.$nextTick(() => {
        
        if (this.all) {
          this.selectedValues = [];
        } else {

          this.selectedValues = this.$attrs["return-object"] ? this.$attrs.items : this.$attrs.items.map((item) => { return item[this.$attrs["item-value"]]});
        }

        this.$emit("selected", this.selectedValues);
      })
    },
    input(value) {
      this.$emit("input", value);
    }
  }
};
</script>


