<template>
  <div>
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <v-container v-if="!loading">
 

      </v-container>
  </div>
</template>
<script>


export default{
  components:{
    
  },
  data(){
    return{
      loading: true,
      data: null,
      name: '',
      loadedMessage: '',
      loaderMessages: []
    }
  },
  async mounted(){
    this.loading = true
    
    this.loading = false

  },
  methods:{
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/admin',
          exact: true,
        },
      ];
    }
  }
}
</script>