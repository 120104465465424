<template>
    <span>
      <Tooltip
        class="my-4 mr-4"
        color="success"
        text="Reactivar"
        top
      >
        <v-icon
          small
          color="success"
          v-bind="$attrs"
          v-on="$listeners"
          @click.stop="openConfirmationDialog = true"
        >
          mdi-play
        </v-icon>
      </Tooltip>
        
      <DialogConfirmation 
        v-if="openConfirmationDialog && !reverse" 
        title="Remover Item" 
        type="warning" 
        :opened="openConfirmationDialog" 
        @on-submit="submit" 
        @on-cancel="openConfirmationDialog = false">
          {{deleteMessage}}
      </DialogConfirmation>
  
      <DialogConfirmation 
        v-else
        title="Restaurar Item" 
        type="warning" 
        :opened="openConfirmationDialog" 
        @on-submit="submit" 
        @on-cancel="openConfirmationDialog = false">
          Tem a certeza que pretende restaurar o Tenant? Ele será restaurado mas não será ligado! Terá de o fazer manualmente
      </DialogConfirmation>
    </span>
  </template>
  
  <script>
    import DialogConfirmation from '@/components/ui/DialogConfirmation.vue';
    import Tooltip from '@/components/ui/Tooltip.vue';
    export default {
      name: 'IconReactivateTenant',
      components: {
        DialogConfirmation,
        Tooltip
      },
      props:{
        reverse:{
          type: Boolean,
          default: false
        },
        deleteMessage:{
          typeof: String,
          default: 'Tem a certeza que pretende remover o item?'
        }
      },
      data() {
        return {
          openConfirmationDialog: false,
        }
      },
      methods: {
        submit() {
          this.openConfirmationDialog = false
          this.$emit('on-submit')
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
  </style>