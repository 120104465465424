var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"primary--text mt-4 ml-4 mb-n12"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-multiple")]),_vm._v(" "),_c('span',[_vm._v("Subscrições")])],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-container',[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v("Opções")])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":""},on:{"click":_vm.openDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Registar")])])],1)],1),_c('v-data-table',{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [(item.invoice_id == null)?_c('p',{staticClass:"caption"},[_vm._v("(Sem fatura associada)")]):_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":item.paid ? 'success' : 'error',"x-small":""},on:{"click":function($event){return _vm.downloadInvoice(item)}}},[_vm._v(_vm._s(item.paid ? 'Paga' : 'Não paga')+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-download-box ")])],1)]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.paid ? 'Sim' : 'Não')+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+"€ ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active ? 'Sim' : 'Não')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.active == false && _vm.$root.session.hasPermission(["super", "subscriptions.update"]))?_c('IconActivate',{staticClass:"mr-2",on:{"click":function($event){return _vm.confirmedDialog(item, ' ativar subscrição?')}}}):_vm._e(),(_vm.$root.session.hasPermission(["super", "subscriptions.write"]))?_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewSubscription(item)}}}):_vm._e(),(_vm.$root.session.hasPermission(["super", "subscriptions.delete"]))?_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteSubscription(item)}}}):_vm._e()]}}])}),(_vm.confirmedObject.openConfirmationDialog)?_c('DialogConfirmation',{attrs:{"title":"Tem a certeza?","type":"warning","opened":_vm.confirmedObject.openConfirmationDialog},on:{"on-submit":_vm.submitFromDialog,"on-cancel":function($event){_vm.confirmedObject.openConfirmationDialog = false}}},[_vm._v(" "+_vm._s(_vm.confirmedObject.message)+" ")]):_vm._e(),_c('AddSubscription',{ref:"AddSubscription",attrs:{"tenant":_vm.tenant,"subscription_types":_vm.subscription_types},on:{"reloadSubscriptions":_vm.searchSubscriptions}}),_c('EditSubscription',{ref:"EditSubscription",attrs:{"tenant":_vm.tenant,"subscription_types":_vm.subscription_types,"selectedSubscription":_vm.selectedSubscription,"method":"update"},on:{"reloadSubscriptions":_vm.searchSubscriptions}}),_c('ErrorSnackbar',{attrs:{"message":_vm.error.message},model:{value:(_vm.error.show),callback:function ($$v) {_vm.$set(_vm.error, "show", $$v)},expression:"error.show"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }