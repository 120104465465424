import BaseApi from "./BaseApi";

export default class ClientUsers extends BaseApi{
    construct() {}

    static async search(params, tenant) {
        return await ClientUsers.getAxiosInstance().get(ClientUsers.getFullApiUrl(tenant),{params: params});
    }

    static async create(params, tenant) {
        let url = ClientUsers.getFullApiUrl(tenant)
        
        return await ClientUsers.getAxiosInstance().post(url,params);
    }

    static async update(params, tenant) {
        let url = ClientUsers.getFullApiUrl(tenant) + params.id
        
        return await ClientUsers.getAxiosInstance().put(url,params);
    }

    static async delete(params, tenant) {
        let url = ClientUsers.getFullApiUrl(tenant) + params.id
        
        return await ClientUsers.getAxiosInstance().delete(url,params);
    }

    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/clientusers/";
    }
    
    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/clientusers/";
    }

}