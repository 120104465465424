<template>
    <div>
      <h1 class="primary--text mt-4 ml-4 mb-n12">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>Subscrições</span>
      </h1>
        <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  @click="openDialog"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16"
          locale="pt-pt"
        >

          <template v-slot:item.invoice="{item}">
            <p class="caption" v-if="item.invoice_id == null">(Sem fatura associada)</p>
            <v-btn v-else
              :color="item.paid ? 'success' : 'error'"
              x-small
              class="ma-2 white--text"
              @click="downloadInvoice(item)"
              >{{ item.paid ? 'Paga' : 'Não paga' }}
              <v-icon
                  right
                  dark
              >
                  mdi-download-box
              </v-icon>
          </v-btn>
          </template>

          <template v-slot:item.paid="{item}">
            {{item.paid ? 'Sim' : 'Não'}}
          </template>

          <template v-slot:item.price="{item}">
            {{item.price }}€
          </template>

          <template v-slot:item.active="{item}">
            {{item.active ? 'Sim' : 'Não'}}
          </template>

          <template v-slot:item.actions="{item}">
            <IconActivate              
              class="mr-2"
              @click="confirmedDialog(item, ' ativar subscrição?')"
              v-if='item.active == false && $root.session.hasPermission(["super", "subscriptions.update"])'
            />

            <IconView
              v-if='$root.session.hasPermission(["super", "subscriptions.write"])'
              class="mr-2"
              @click="viewSubscription(item)"
            />
            <IconRemove              
              v-if='$root.session.hasPermission(["super", "subscriptions.delete"])'
              class="mr-2"
              @on-submit="deleteSubscription(item)"
            />
          </template>
        </v-data-table>
        <DialogConfirmation 
          title="Tem a certeza?" 
          type="warning" 
          v-if="confirmedObject.openConfirmationDialog"
          :opened="confirmedObject.openConfirmationDialog" 
          @on-submit="submitFromDialog" 
          @on-cancel="confirmedObject.openConfirmationDialog = false">
            {{ confirmedObject.message }}
        </DialogConfirmation>
        <AddSubscription ref="AddSubscription" :tenant="tenant" :subscription_types="subscription_types" @reloadSubscriptions="searchSubscriptions"/>
        <EditSubscription ref="EditSubscription" :tenant="tenant" :subscription_types="subscription_types" @reloadSubscriptions="searchSubscriptions" :selectedSubscription="selectedSubscription" method="update"/>
        <ErrorSnackbar v-model="error.show" :message="error.message"/>
      </div>
    </div>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconActivate from '@/components/ui/IconActivate.vue';
import IconView from '@/components/ui/IconView.vue';
import Subscription from "@/api/Subscription.js";
import Invoice   from "@/api/Invoice.js";
import SubscriptionTypes   from "@/api/SubscriptionTypes.js";
import AddSubscription from "@/components/tenants/subscriptions/AddSubscription"
import EditSubscription from "@/components/tenants/subscriptions/AddSubscription"
import DialogConfirmation from "@/components/ui/DialogConfirmation"
import ErrorSnackbar from '@/components/ui/ErrorSnackbar.vue';

export default {
  components: {
    IconActivate,
    IconRemove,
    IconView,
    EditSubscription,
    AddSubscription,
    DialogConfirmation,
    ErrorSnackbar
  },
  props:['tenant'],
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchSubscriptions()}, 10);
      },
      deep: true,
    },
  },
  data: () => ({
    subscription_types: [],
    selectedSubscription: null,
    confirmedObject:{
      openConfirmationDialog: false,
      object: null,
      place: null,
      message: ''
    },
    headers: [
      {
        text: 'Inicio',
        sortable: false,
        value: 'subscription_start',
      },
      {
        text: 'Fim',
        sortable: false,
        value: 'subscription_end',
      },
      {
        text: 'Paga',
        sortable: false,
        value: 'paid',
      },
      {
        text: 'Preço',
        sortable: false,
        value: 'price',
      },
      {
        text: 'Registado por',
        sortable: false,
        value: 'username',
      },
      {
        text: 'Fatura',
        sortable: false,
        value: 'invoice',
      },
      {
        text: 'Tipo',
        sortable: false,
        value: 'type.name',
      },
      {
        text: 'Ativa',
        sortable: false,
        value: 'active',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
    },
    users:[],
    error:{
      show: false,
      message: ''
    }
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "subscriptions.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    confirmedDialog(item, msg){
      /*if(item.paid == false){
        this.error.message = 'Só pode ativar subscrições que estejam pagas'
        this.error.show = true
        return
      }*/
      this.confirmedObject.object = item
      this.confirmedObject.place = 'activateSubscription'
      this.confirmedObject.message = 'Tem a certeza que pretende prosseguir para ' + msg
      this.confirmedObject.openConfirmationDialog = true
    },
    submitFromDialog(){
      if(this.confirmedObject.place == 'activateSubscription')
        this.activateSubscription(this.confirmedObject.object)
      
      
      this.confirmedObject.openConfirmationDialog = false
      this.confirmedObject.object = null
      this.confirmedObject.place = null
    },
    async activateSubscription(item){
      let params = {
        tenant: this.tenant,
        subscription: item,
        id: item.id
      }
      await Subscription.activate(params).then(() => {
        
      });
      this.searchSubscriptions()
      this.$emit('refresh')
    },
    downloadInvoice(item){
      Invoice['download'](item.invoice_id, this.tenant).then((response) => {
          let documentName =  item.invoice_id

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", documentName + '.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
      }).catch(err => {
          this.loading = false;
        console.log(err)
          if(err.response.status == 422) {

              this.$refs.form.setErrors(err.response.data.errors);
              
              return;
          }

          this.error.title = "Erro " + err.response.status;

          this.error.message = err.response.data.message;

      });
    },    
    showWarningIcon(dt){
      var fDt = new Date(dt);
      var today = new Date();
      var Difference_In_Time = fDt.getTime() - today.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if(Difference_In_Days <= 7)
        return true
      return false
    },
    openDialog(){
      this.$refs.AddSubscription.openDialog()
    },
    formatData(dt){
      return dt.split(' ')[0]
    },
    fillBaseData() {

      if(localStorage["subscriptions-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["subscriptions-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }

      SubscriptionTypes.list().then(({data}) => {
        this.subscription_types = data;
      });
    },
    searchSubscriptions(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        tenant: this.tenant
      };
    
      Object.assign(request, filter);

      localStorage["subscriptions-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Subscription.search(request).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetSubscription()
    {
      this.filter = {};

      this.searchSubscriptions();
    },
    viewSubscription(item){
      this.selectedSubscription = item
      this.$refs.EditSubscription.openDialog()
    },
    deleteSubscription(item){
      Subscription.delete(item, this.tenant)
          .then(() => {
            this.searchSubscriptions();
          });
    },
  },
  computed: {
    
  }
};
</script>
