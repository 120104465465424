<template>
    <v-container fluid>
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit"  class="mb-16">
                <v-progress-linear v-if="loading"
                    indeterminate
                    class="global-loader"
                ></v-progress-linear>
                <fieldset :disabled="loading">
                    <v-row>             
                        <v-col cols="12" md="auto">
                            <v-switch
                                v-model="fields.is_active"
                                inset
                                label="Ativo"
                                color="success"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="5">
                            <validation-provider v-slot="{ errors }" vid="Nome" name="Nome" rules="required">
                                <v-text-field
                                    v-model="fields.name"
                                    prepend-inner-icon="mdi-account-details"
                                    label="Nome *"
                                    outlined dense
                                    clearable
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="2">
                            <validation-provider v-slot="{ errors }" vid="Quantidade" name="Quantidade" rules="required">
                                <v-text-field
                                    v-model="fields.quantity"
                                    prepend-inner-icon="mdi-account-details"
                                    label="Quantidade *"
                                    type="number"
                                    outlined dense
                                    clearable
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="2">
                            <validation-provider v-slot="{ errors }" vid="Desconto" name="Desconto" rules="required">
                                <v-text-field
                                    v-model="fields.discount"
                                    prepend-inner-icon="mdi-account-details"
                                    label="Desconto *"
                                    type="number"
                                    outlined dense
                                    clearable
                                    :error-messages="errors"
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="3">
                            <validation-provider v-slot="{ errors }" vid="Validade" name="Validade" rules="required">
                                <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="fields.expiration_date"
                                        label="Validade *"
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        dense outlined
                                        :error-messages="errors"
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="fields.expiration_date"
                                    @input="menu2 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-checkbox
                                v-model="addURL"
                                label="Usar URL"
                                dense outlined
                                @change="addURLFunction"
                            />
                        </v-col>
                        <v-col cols="12" md="10" v-if="addURL">
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-text-field disabled text dense value="https://backoffice.ignoto.eu/promocode/"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="fields.promocode_url"
                                        persistent-hint
                                        hint="Nunca utilizar barras '/' no URL. Irá trazer problemas. Usar '-' ou '_'"
                                        label="URL promocode"
                                        dense outlined
                                    />
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-btn outlined class="primary" dark icon @click="copyToClipboard">
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" :md="fields.email == false ? '12' : '3'">
                            <v-checkbox
                                v-model="fields.email"
                                label="Ativar promoção via email"
                                dense outlined
                                @change="addURL = true; addURLFunction();"
                            />
                        </v-col>
                        <v-col cols="12" md="8" v-if="fields.email">
                            <v-text-field disabled text dense :value="'https://backoffice.ignoto.eu/promocode/client-promotion/' + fields.promocode_url"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1" v-if="fields.email">
                            <v-btn outlined class="primary" dark icon @click="copyToClipboardEmail">
                                <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <span class="font-weight-bold">Insira o código:</span>
                        </v-col>
                        <v-col cols="12" md="6">
                            <validation-provider v-slot="{ errors }" vid="Código" name="Código" rules="required">
                                <v-otp-input   
                                    v-model="fields.code"
                                    length="8"
                                    dense
                                    :error-messages="errors"
                                ></v-otp-input>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6" align="center" justify="space-around" >
                        <v-btn :disabled="invalid || fields.code.length < 8" depressed color="primary" type="submit">
                            Gravar
                        </v-btn>
                    </v-row>
                </fieldset>
            </v-form>
        </validation-observer>
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Promocode gravado com sucesso
        </DialogSuccess>
    </v-container>
</template>
<script>
import Promocodes from "@/api/Promocodes.js"
import DialogSuccess from '@/components/ui/DialogSuccess.vue';

export default{
    name:"Promocode",
    components:{
        DialogSuccess
    },
    props:{
        method: String,
        promocode: Object
    },
    watch:{
        promocode:{
            immediate: true,
            handler(val){
                if(val == null)
                    return
                if(val == undefined)
                    return
                
                this.fields = this.promocode

                if(this.promocode.promocode_url != null)
                    this.addURL = true
            }
        }
    },
    data(){
        return{
            loading: true,
            menu2: false,
            success: false,
            addURL: false,
            fields:{
                id: null,
                email: false,
                promocode_url: null,
                name: null,
                code: '',
                quantity: null,
                discount: null,
                expiration_date: null,
                is_active: true
            }
        }
    },
    mounted(){
        this.loading = false
    },
    methods:{
        copyToClipboard(){
            navigator.clipboard.writeText('https://backoffice.ignoto.eu/promocode/' + this.fields.promocode_url);
        },
        copyToClipboardEmail(){
            navigator.clipboard.writeText('https://backoffice.ignoto.eu/promocode/client-promotion/' + this.fields.promocode_url);
        },
        addURLFunction(){
            if(this.addURL == false)
                return
            if(this.fields.promocode_url != null)
                return

            this.fields.promocode_url = this.fields.id
        },
        onSuccess(){
            this.success = false
            this.$router.push('/promocodes')
        },
        submit(){
            Promocodes[this.method](this.fields, this.$route.params.id).then(()    =>  {
                this.success = true
            })
        }
    }
}
</script>