<template>
    <section class="mt-16">
      
      <TenantsComponent />
      </section>
</template>
<script>
    
import TenantsComponent from '@/components/tenants/TenantsComponent.vue';

export default{
    components: {
        TenantsComponent
    }
}
</script>