<template>
    <div>        
        <v-text-field
            v-model="generalPassword"
            ref="passwordField"
            v-bind="$attrs"
            prepend-inner-icon="mdi-form-textbox-password"
            type="password"
            label="Password"
            clearable
            :rules="[rules.required, rules.min, rules.oneNumber, rules.specialChar, rules.upperCase, rules.lowerCase]"
            @keyup="managePassword"
        ></v-text-field>
        <password-meter :password="generalPassword" />
    </div>
</template>
<script>
import passwordMeter from "vue-simple-password-meter";

export default{
    components:{
        passwordMeter
    },
    model: {
        prop: 'password',
        event: 'changed'
    },
    props:{
        password: {
            typeof: String, 
            default: ''
        }
    },
    watch: { 
        password: {
            immediate: true, 
            handler (val) {

                val = val == undefined ? [] : val;

                this.generalPassword= val;

                this.$emit("changed", this.generalPassword);
            }
        },
        generalPassword: {
            immediate: true, 
            handler (val) {
                
                val = val == undefined ? [] : val;

                this.generalPassword= val;
                
                this.$emit("changed", val);
            }
        },
    },
    data(){
        return{
            generalPassword: '',
            rules:{
                required: value => !!value || 'Required.',
                min: v => v.length >= 10 || 'Min 10 characters',
                upperCase: value => /[A-Z]/.test(value) || 'At least one Capital Letter',
                lowerCase: value => /[a-z]/.test(value) || 'At least one lowercase Letter',
                oneNumber: v => /\d/.test(v) || 'At least one number',
                specialChar: v => /[*@!#%&()^~{}]+/.test(v) || 'At least one Special'
            },
        }
    },
    methods:{
        checkValidation(){
            if(this.generalPassword == '')
                return true;
                
            return this.$refs.passwordField.validate()
        },
        managePassword(){
            
        },
    }
}
</script>