import BaseApi from "./BaseApi";

export default class Promocodes extends BaseApi{
    construct() {}

    static async search(params) {
        return await Promocodes.getAxiosInstance().get(Promocodes.getApiUrl(),{params: params});
    }

    static async create(params) {
        return await Promocodes.getAxiosInstance().post(Promocodes.getApiUrl(),params);
    }

    static async update(params, id) {
        return await Promocodes.getAxiosInstance().put(Promocodes.getApiUrl() + id,params);
    }

    static async find(id) {
        return await Promocodes.getAxiosInstance().get(Promocodes.getApiUrl() + id);
    }

    static async list() {
        return await Promocodes.getAxiosInstance().get(Promocodes.getApiUrl() + 'list');
    }
    

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/promocodes/";
    }
}