import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Login2Fa from '../views/Login2Fa.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Layout from '../Layout.vue'
import Dashboard from '../views/Dashboard.vue'
import Notifications from '../views/Notifications.vue'
import Tenants from '../views/Tenants.vue'
import CreateTenant from '../components/tenants/CreateTenant'
import UpdateTenant from '../components/tenants/UpdateTenant'
import Roles from '../views/Roles.vue'

import Permissions from '../views/Permissions.vue'
import Logs from '../views/Logs.vue'
import NotFound from '../views/NotFound.vue'
import CreateRole from '../components/roles/CreateRole.vue'
import UpdateRole from '../components/roles/UpdateRole.vue'

import UpdateUser from '../views/settings/users/UpdateUser.vue'
import CreateUser from '../views/settings/users/CreateUser.vue'
import Users from '../views/settings/users/Users.vue'

import SubscriptionTypes from '../views/settings/subscriptions_type/SubscriptionsTypes.vue'
import UpdateSubscriptionTypes from '../views/settings/subscriptions_type/UpdateSubscriptionType.vue'
import CreateSubscriptionTypes from '../views/settings/subscriptions_type/CreateSubscriptionType.vue'

import Promocodes from '@/views/promocodes/Promocodes.vue'
import CreatePromocodes from '@/views/promocodes/CreatePromocodes.vue'
import UpdatePromocodes from '@/views/promocodes/UpdatePromocodes.vue'

import UsedNifs from "@/views/used-nifs/UsedNifs.vue"


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/2fa',
        component: Login2Fa
    },
    {
        path: '/verify-email/:id/:hash',
        component: EmailConfirmation
    },
    {
        path: '/reset/:id/:hash',
        component: ResetPassword
    },
    {
        path: '/admin',
        component: Layout,
        children: [
            {
                path: '/admin',
                component: Dashboard
            },
            {
                path: '/used-nifs',
                component: UsedNifs
            },
            {
                path: '/promocodes',
                component: Promocodes
            },
            {
                path: '/promocodes/create',
                component: CreatePromocodes
            },
            {
                path: '/promocodes/:id',
                component: UpdatePromocodes
            },
            {
                path: '/tenants',
                component: Tenants
            },
            {
                path: '/admin/notifications',
                component: Notifications,
            },
            {
                path: '/tenants/Create',
                component: CreateTenant
            },
            {
                path: '/tenants/update/:id',
                component: UpdateTenant
            },
            {
                path: '/tenants/update/:id/:place/:invoice_id',
                component: UpdateTenant
            },
            {
                path: '/internal_management/roles',
                component: Roles
            },
            {
                path: '/internal_management/users',
                component: Users
            },
            {
                path: '/internal_management/permissions',
                component: Permissions
            },
            {
                path: '/internal_management/subscription-types',
                component: SubscriptionTypes
            },
            {
                path: '/internal_management/subscription-types/create',
                component: CreateSubscriptionTypes
            },
            {
                path: '/internal_management/subscription-types/:id',
                component: UpdateSubscriptionTypes
            },
            {
                path: '/internal_management/logs',
                component: Logs
            },
            {
                path: '/admin/settings/roles/create',
                component: CreateRole,
            },
            {
                path: '/admin/settings/roles/:id',
                component: UpdateRole,
            },
            {
                path: '/admin/settings/my-user',
                component: UpdateUser
            },
            {
                path: '/admin/settings/createuser',
                component: CreateUser
            },
            {
                path: '/admin/settings/users/create',
                component: CreateUser,
            },
            {
                path: '/admin/settings/users/:id',
                component: UpdateUser,
            },
            {
                path: '/admin/settings/my-user',
                component: UpdateUser,
            },
        ]
    },
    { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
