<template>
  <span>
    <Tooltip
      class="my-4 mr-4"
      color="primary"
      text="Visualizar"
      top
    >
      <v-icon
        small
        color="primary"
        v-bind="$attrs"
        v-on="$listeners"
      >
        mdi-eye
      </v-icon>
    </Tooltip>
  </span>
</template>

<script>
  import Tooltip from '@/components/ui/Tooltip.vue';
  export default {
    name: 'uiIconView',
    components: {
      Tooltip
    },
  }
</script>

<style lang="scss" scoped>
</style>