var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"primary--text mt-4 ml-4 mb-n12"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-multiple")]),_vm._v(" "),_c('span',[_vm._v("Utilizadores")])],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-container',[_c('v-speed-dial',{attrs:{"absolute":"","top":"","right":"","direction":"bottom","open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-large":"","fab":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},'v-btn',attrs,false),on),[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}])},[_c('span',[_vm._v("Opções")])])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":"secondary","large":""},on:{"click":_vm.openDialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Registar")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"mt-12 mb-n12",attrs:{"color":"success","label":"Ativos","hide-details":""},on:{"change":_vm.searchUsers},model:{value:(_vm.filter.active),callback:function ($$v) {_vm.$set(_vm.filter, "active", $$v)},expression:"filter.active"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-16",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.tableFooter,"options":_vm.options,"locale":"pt-pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.expires_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatData(item.expires_at))+" "),(_vm.showWarningIcon(item.expires_at))?_c('Tooltip',{staticClass:"my-4 mr-4",attrs:{"color":"red","text":"Data expirada ou a expirar","top":""}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-circle-outline")])],1):_vm._e()]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [(item.paid)?_c('Tooltip',{staticClass:"my-4 mr-4",attrs:{"color":"green","text":"Paga","top":""}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1):_c('Tooltip',{staticClass:"my-4 mr-4",attrs:{"color":"red","text":"Por pagar","top":""}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert-circle-outline")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$root.session.hasPermission(["super", "accesses.write"]))?_c('IconView',{staticClass:"mr-2",on:{"click":function($event){return _vm.viewUser(item)}}}):_vm._e(),(_vm.$root.session.hasPermission(["super", "accesses.delete"]))?_c('IconRemove',{staticClass:"mr-2",on:{"on-submit":function($event){return _vm.deleteUser(item)}}}):_vm._e()]}}])}),_c('AddUsers',{ref:"AddUser",attrs:{"tenant":_vm.tenant},on:{"reloadUsers":_vm.searchUsers}}),_c('EditUsers',{ref:"EditUser",attrs:{"tenant":_vm.tenant,"selectedUser":_vm.selectedUser,"method":"update"},on:{"reloadUsers":_vm.searchUsers}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }