<template>
    <v-card class="elevation-10">
      <v-card-text>
        <validation-observer ref="form" v-slot="{ invalid }">
          <v-form @submit.prevent="submit"  class="mb-16">
            <v-progress-linear v-if="loading"
                indeterminate
                class="global-loader"
              ></v-progress-linear>
            <fieldset :disabled="loading">
              <v-row>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="required">
                    <v-text-field
                      v-model="fields.name"
                      prepend-inner-icon="mdi-account-details"
                      label="Nome *"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2">
                  <validation-provider v-slot="{ errors }" vid="name" name="Nome" rules="|">
                    <v-text-field
                      v-model="fields.order"
                      prepend-inner-icon="mdi-account-details"
                      label="Ordem aparição"
                      type="number"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2">
                  <validation-provider v-slot="{ errors }" vid="Preço" name="Preço" rules="required">
                    <v-text-field
                      v-model="fields.price"
                      prepend-inner-icon="mdi-account-details"
                      label="Preço *"
                      clearable
                      type="number"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2">
                  <validation-provider v-slot="{ errors }" vid="Mes" name="Mes" rules="required">
                    <v-text-field
                      v-model="fields.in_month"
                      prepend-inner-icon="mdi-calendar"
                      label="Meses *"
                      clearable
                      hint="O preço total de checkout será calculado da seguinte forma: preço x meses"
                      persistent-hint
                      type="number"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2">
                  <validation-provider v-slot="{ errors }" vid="Tipo Instituição" name="Tipo Instituição" rules="|">
                    <v-select
                      v-model="fields.institutional_type"
                      :items="['private', 'public']"
                      prepend-inner-icon="mdi-calendar"
                      label="Tipo instituição"
                      clearable
                      persistent-hint
                      hint="Deixar a null o que for Trial"
                      type="number"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2">
                  <validation-provider v-slot="{ errors }" vid="Dias" name="Dias" rules="required">
                    <v-text-field
                      v-model="fields.days"
                      prepend-inner-icon="mdi-account-details"
                      label="Dias *"
                      clearable
                      type="number"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="4">
                  <validation-provider v-slot="{ errors }" vid="Colaboradores" name="Colaboradores" rules="required">
                    <v-text-field
                      v-model="fields.n_workers"
                      prepend-inner-icon="mdi-account-details"
                      label="Qtd. Colaboradores *"
                      clearable
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider v-slot="{ errors }" vid="Visibilidade" name="Visibilidade" rules="required">
                    <v-switch :error-messages="errors"
                        v-model="fields.client_visible"
                        label="Visivel ao cliente?"/>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="3">
                  <validation-provider v-slot="{ errors }" vid="site" name="site" rules="required">
                    <v-switch :error-messages="errors"
                        v-model="fields.website_direct_promo"
                        label="Aplicação direta no site?"/>
                  </validation-provider>
                </v-col>                
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" vid="description" name="Descrição" rules="|">
                    <v-textarea
                      v-model="fields.description"
                      prepend-inner-icon="mdi-account-details"
                      label="Descrição *"
                      clearable
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider v-slot="{ errors }" vid="promocode" name="Promocode" rules="|">
                    <v-autocomplete
                      v-model="fields.promocode_id"
                      :items="promocodes"
                      prepend-inner-icon="mdi-account-details"
                      label="Promocode"
                      item-text="name"
                      item-value="id"
                      clearable
                      :error-messages="errors"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
                
              </v-row>
              <v-row class="mt-6" align="center" justify="space-around">
                <v-btn :disabled="invalid" depressed color="primary" type="submit">
                  Gravar
                </v-btn>
              </v-row>
            </fieldset>
          </v-form>
          <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Tipo de subscrição gravado com sucesso!
          </DialogSuccess>
        </validation-observer>
      </v-card-text>
    </v-card>
  </template>
  <script>
  import SubscriptionTypes from "@/api/SubscriptionTypes.js";
  import Promocodes from "@/api/Promocodes.js";
  
  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  
  export default {
    components: {
        
      DialogSuccess
    },
    props: {
     subscription: Object,
     method: String
   },
   watch: { 
    subscription: {
        immediate: true, 
        handler (val) {
          if(!val) {
            return;
          }
  
          let data = {...val};
  
          this.fields = data;
  
          this.fields.id = data.id;
        }
      },
    },
    data: () => ({
      fields: {
        id: null,
        name: null,
        description: null,
        order: null,
        price: null,
        days: null,
        client_visible: false,
        website_direct_promo: false,
        n_workers: null,
        in_month: 1,
        institutional_type: null,
        promocode_id: null
      },
      loading:false,
      error: {
        title: '',
        message: '',
      },
      users: [],
      permissions: [],
      success: false,
      promocodes: []
    }),
    mounted(){
      this.fillBaseData()        
    },
    methods:{
      fillBaseData(){
        Promocodes.list().then((resp) =>  {
          this.promocodes = resp.data
        })
      },
      onSuccess() {
        if(this.fields.id != null) {
          this.success = false
          return
        }
        this.$router.push('/internal_management/subscription-types');
      },
      submit() {
  
        this.$refs.form.validate().then((result) => {
          this.loading = true;
          
          if(!result){
            this.loading = false;
            return;
          }
  
          let data = {...this.fields};
  
          SubscriptionTypes[this.method](data, this.fields.id).then(() => {
  
            this.success = true;
  
            this.loading = false;
            
          }).catch(err => {
            this.loading = false;
  
              if(err.response.status == 422) {
  
                this.$refs.form.setErrors(err.response.data.errors);
                
                return;
              }
  
              this.error.title = "Erro " + err.response.status;
  
              this.error.message = err.response.data.message;
  
            });
        })
      }
    },
    computed: {
      
    }
  };
  </script>
  