var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-theme-provider',{attrs:{"root":""}},[_c('v-app',{style:({'--group-selected': _vm.$vuetify.groupSelectedColor})},[_c('v-navigation-drawer',{attrs:{"app":"","mini-variant":_vm.showMini,"width":"300"},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{attrs:{"nav":""}},[(_vm.loading)?_vm._l((_vm.items.length),function(i){return _c('v-skeleton-loader',{key:i,attrs:{"type":"list-item"}})}):_vm._l((_vm.items),function(item){return [(item.items == 0)?_c('v-tooltip',{key:item.title,attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":item.link,"exact":item.exact}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]):_c('v-tooltip',{key:item.title,attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-group',_vm._g(_vm._b({attrs:{"group":item.group},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true)},'v-list-group',attrs,false),on),[_vm._l((item.items),function(child){return [(child.has_permission)?_c('v-tooltip',{key:child.title,attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":child.link,"exact":child.exact}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(child.icon))])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.title)}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(child.title))])]):_vm._e()]})],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]}),_c('v-divider')],2)],1),(!_vm.loading)?_c('v-app-bar',{attrs:{"dense":"","fixed":"","app":"","clipped-left":false}},[_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({on:{"click":_vm.openMenu}},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,2352910227)},[_c('span',[_vm._v("Menu")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","link":"","to":"/admin/notifications"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":_vm.notifications,"value":_vm.notifications,"color":"error","overlap":""}},[_c('v-icon',{attrs:{"color":_vm.notifications > 0 ? 'secondary' : ''}},[_vm._v("mdi-bell")])],1)],1)]}}],null,false,2578673164)},[_c('span',[_vm._v("Notificações")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":'/admin/settings/my-user',"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,4143772466)},[_c('span',[_vm._v("Conta")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.toggleTheme}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.$vuetify.theme.dark ? "mdi-white-balance-sunny" : "mdi-weather-night"))])],1)]}}],null,false,569232028)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.theme.dark ? "Modo Claro" : "Modo Escuro"))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-logout")])],1)]}}],null,false,3448513929)},[_c('span',[_vm._v("Sair")])])],1):_vm._e(),_c('v-main',[(_vm.loading)?_c('v-progress-linear',{staticClass:"global-loader",attrs:{"indeterminate":""}}):_c('v-container',_vm._g({attrs:{"fluid":""}},_vm.$listeners),[_c('v-row',[_c('v-col',{attrs:{"offset":"0","cols":"12","offset-lg":"1","lg":"10"}},[_c('router-view',{on:{"read-notifications":function($event){_vm.notifications = 0}}})],1)],1)],1)],1),_c('v-footer',{attrs:{"padless":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "),_c('strong',{staticClass:"primary--text"},[_vm._v("Ignoto V"+_vm._s(_vm.getVersion()))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }