import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './plugins/validations'
import { ValidationObserver, ValidationProvider} from 'vee-validate';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@babel/polyfill'
import '@/assets/style.css'
import axios from "axios";
import router from './router'
import dayjs from 'dayjs'
import VCurrencyField from 'v-currency-field'
import DatetimePicker from 'vuetify-datetime-picker'
import VueLazyload from 'vue-lazyload'
import EncryptValue from '@/plugins/encrypt'
import StatusIndicator from 'vue-status-indicator';
import msal from 'vue-msal'
import VueCookies from 'vue-cookies'

Vue.use(msal, {
  auth: {
    clientId: process.env.VUE_APP_MICROSOFT_CLIENT,
  },
});

Vue.config.productionTip = false

Vue.component('ValidationProvider', ValidationProvider);

Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueLazyload)
Vue.use(VueCookies, { expires: '7d'})
Vue.use(StatusIndicator);

Vue.use(DatetimePicker);

axios.interceptors.request.use(
  function(config) {
        config.withCredentials = true;
        config.headers['Content-Type'] = "application/json";
        config.headers['Accept'] = "application/json";
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

Vue.prototype.$fatalError = true;

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    router.push('/')
  }
  return Promise.reject(error)
})

const themes = [
    {
        name: "Padrão",
        light: {
            primary: "#673ab7",
            primaryLight: "#673ab710",
            secondary: "#3f51b5",
            accent: "#009688",
            error: "#f44336",
            warning: "#ffc107",
            info: "#03a9f4",
            success: "#4caf50"
        },
        dark: {
            primary: "#9575cd",
            primaryLight: "#9575cd10",
            secondary: "#64b5f6",
            accent: "#00bcd4",
            error: "#f44336",
            warning: "#ffc107",
            info: "#607d8b",
            success: "#8bc34a"
      },
    },
    {
        name: "Tema 1",
        light: {
            primary: "#009688",
            primaryLight: "#00968810",
            secondary: "#8bc34a",
            accent: "#cddc39",
            error: "#f44336",
            warning: "#ff9800",
            info: "#2196f3",
            success: "#4caf50"
      },
        dark:  {
            primary: "#009688",
            primaryLight: "#00968810",
            secondary: "#8bc34a",
            accent: "#cddc39",
            error: "#f44336",
            warning: "#ff9800",
            info: "#2196f3",
            success: "#4caf50"
        }
    },
    {
        name:"Tema 2",
        light: {
            primary: "#607d8b",
            primaryLight: "#607d8b10",
            secondary: "#009688",
            accent: "#00bcd4",
            error: "#e91e63",
            warning: "#ffc107",
            info: "#03a9f4",
            success: "#8bc34a"
        },
        dark:{
            primary: "#9e9e9e",
            primaryLight: "#9e9e9e10",
            secondary: "#B2DFDB",
            accent: "#00bcd4",
            error: "#e91e63",
            warning: "#ffc107",
            info: "#03a9f4",
            success: "#8bc34a"
        },
    }
];

Vue.prototype.$themes = themes;

Vue.prototype.$encryptValue = EncryptValue

Vue.mixin({
  methods: {
    setTheme: function(theme) {
      const dark = theme.dark;

        const light = theme.light;
        
        Object.keys(dark).forEach(i => {
            this.$vuetify.theme.themes.dark[i] = dark[i];
        });
        Object.keys(light).forEach(i => {
            this.$vuetify.theme.themes.light[i] = light[i];
        });
          
          this.$vuetify.groupSelectedColor = this.$vuetify.theme.dark ? theme.dark.primaryLight
          : theme.light.primaryLight;
    },
    formatDate(date){
      return date == null ? null : dayjs(date).format('DD/MM/YYYY');
    },
  }
});

Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: true
})


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
