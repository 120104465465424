import { extend } from 'vee-validate';
import { required, email, numeric, min_value, max_value } from 'vee-validate/dist/rules'
import dayjs from 'dayjs'

extend('required', {
    ...required,
    message: '{_field_} é obrigatório',
})

extend('email', {
    ...email,
    message: '{_field_} deve ser um email válido',
})

extend('numeric', {
    ...numeric,
    message: '{_field_} deve ser numérico',
})

extend('min_value', {
    ...min_value,
    message: '{_field_} deve ser maior ou igual a {min}',
})

extend('max_value', {
    ...max_value,
    message: '{_field_} deve ser menout ou igual a {max}',
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Confirmação de password diferente'
});

extend("date_min", {
  params: ['target'],
    validate(value, { target }) {

        if (value.length == 0) {
            return true;
        }

        value = value.split("/");

        target = target.split("/");

        value = value[2] + "-" + value[1] + "-" + value[0];

        target = target[2] + "-" + target[1] + "-" + target[0];

        return dayjs(value).isSame(dayjs(target)) || dayjs(value).isAfter(dayjs(target));
  },
  message: "A data tem de ser maior ou igual a {target}!"
});