<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="label != ''"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                >
                {{ label }}
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="text-h5" v-if="method=='create'">Registar role</span>
                    <span class="text-h5" v-else>Editar role</span>
                </v-card-title>
                <v-card-text>
                    <validation-observer ref="form_role" v-slot="{ }">      
                    <v-form class="mb-16" @submit.prevent="addRole">
                        <fieldset :disabled="loading">
                        
                        </fieldset>         
                    </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Role gravado com sucesso
        </DialogSuccess>
    </v-row>
  </template>
  <script>

  import DialogSuccess from '@/components/ui/DialogSuccess.vue';
  
  export default {
    components:{
        DialogSuccess,
    },
    props:{
        label: {
            Type: String,
            default: ''
        },
        tenant:{
            Type: String,
            default: null
        },
        method:{
            Type: String,
            default: 'create'
        },
        selectedRole:{
            default: null
        }
    },
    watch:{
        selectedRole:{
            immediate: true,
            handler(val){
                if(!val) {
                return;
                }

                let data = {...val};

                this.fields = data;

                this.fields.id = data.id;
                
            }
        }
    },
    data(){
        return{
            dialog: false,
            loading: false,         
            success: false, 
            fields: {
                id: null,
                name: null,
                display_name:null,
                users:[],
                permissions: [],
            },
        }
    },
    methods:{
        onSuccess() {
            this.dialog = false
            this.success = false
            this.$emit('reloadRoles')
        },
        openDialog(){
            this.dialog = true
        },
        addRole(){         
            
        },
    }
  }
  </script>