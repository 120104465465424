import BaseApi from "./BaseApi";
export default class Subscription extends BaseApi{
    construct() {}

    static async search(params) {
        return await Subscription.getAxiosInstance().get(Subscription.getApiUrl(),{params: params});
    }

    static async create(params) {
        let url = Subscription.getApiUrl()
        
        return await Subscription.getAxiosInstance().post(url,params);
    }

    static async delete(params) {
        let url = Subscription.getApiUrl() + params.id
        
        return await Subscription.getAxiosInstance().delete(url);
    }

    static async update(params, id) {
        let url = Subscription.getApiUrl() + 'update/' + id


        return await Subscription.getAxiosInstance().post(url, params);
    }

    static async list(params) {
        let url = Subscription.getApiUrl() + "list";

        return Subscription.getAxiosInstance().get(url,{params: params});
    }

    static async activate(params){
        let url = Subscription.getApiUrl() + 'activate-subscription'

        return await Subscription.getAxiosInstance().post(url, params)
    }

    static getFullApiUrl(tenant){
        return BaseApi.getApiUrl() + "api/" + tenant + "/admin/subscriptions/";
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/subscriptions/";
    }
}