<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="4">
                <v-text-field
                    readonly
                    v-model="tenant.name"
                    dense outlined
                    label="Nome empresa"
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    readonly
                    v-model="tenant.address"
                    dense outlined
                    label="Morada"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    readonly
                    v-model="tenant.postal_code"
                    dense outlined
                    label="Cod. Postal"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    readonly
                    v-model="tenant.local"
                    dense outlined
                    label="Localidade"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    readonly
                    v-model="tenant.country"
                    dense outlined
                    label="Pais"
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                    readonly
                    v-model="tenant.nif"
                    dense outlined
                    label="NIF"
                />
            </v-col>
        </v-row>
        <v-row v-if="tenant.manager != null">
            <v-col cols="12" md="4">
                <v-text-field
                    readonly
                    v-model="tenant.manager.name"
                    dense outlined
                    label="Nome gestor"
                />                
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    readonly
                    v-model="tenant.manager.email"
                    dense outlined
                    label="Email gestor"
                />                
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field
                    readonly
                    v-model="tenant.manager.phone_number"
                    dense outlined
                    label="Contacto gestor"
                />                
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default{
    name:"InvoiceData",
    props:['tenant'],
    mounted(){
        console.log("AQUI")
        console.log(this.tenant)
    }
}
</script>